import { withStyles, WithStyles } from '@material-ui/styles';
import { styles } from 'src/components/home/search/search.styles';
import { withStrings, WithStrings } from 'components/locale';
import React, { createRef } from 'react';
import { Col, Container, Label, Input, Row } from 'reactstrap';
import Button from 'components/common/button/button';
import LocationInput, { Location } from 'components/common/locationInput/locationInput';
import { getFirstSugestion } from 'components/common/locationInput/getFirstSugestion';
import { SearchModel } from 'src/model/searchModel';
import { navigate } from '@reach/router';
import { withPrefix } from 'gatsby';
import { serializeToQuery } from 'src/services/querySerializer';
import { FaArrowCircleRight } from 'react-icons/fa';

interface Props extends WithStyles<typeof styles>, WithStrings {
    searchModel: SearchModel;
    onSearchModelUpdated: (model: SearchModel) => void;
}

interface State {
    location: Location;
    keywords: string;
}

class UpdateSearch extends React.Component<Props, State> {
    formRef = createRef<HTMLFormElement>();

    constructor(props: Props) {
        super(props);
        const searchModel = props.searchModel;
        this.state = {
            location: {
                locationName: searchModel.locationName || '',
                lat: searchModel.lat || '',
                lng: searchModel.lng || '',
            },
            keywords: searchModel.keywords || '',
        };
    }

    render = () => {
        const { strings, classes } = this.props;
        const { location, keywords } = this.state;
        return (
            <form
                ref={this.formRef}
                className={classes.searchForm}
            >
                <Container>
                    <h1>{strings.homePage.title}</h1>
                    <Row>
                        <Col md={5}>
                            <Label for="keyword">{strings.common.keywordsSearchLabel}</Label>
                                <Input
                                    id="keyword"
                                    placeholder={
                                        strings.common.keywordsSearchPlaceHolder
                                    }
                                    onChange={this.onKeywordsChange}
                                    defaultValue={keywords}
                                    autoComplete={'off'}
                                    maxLength={150}
                                />

                        </Col>
                        <Col md={4} lg={3}>
                            <Label for="location">{strings.common.locationLabel}</Label>
                                <LocationInput
                                    placeholder={strings.common.locationPlaceHolder}
                                    onLocationChanged={this.onLocationChanged}
                                    value={location.locationName}
                                />

                        </Col>
                        <Col md={2}>
                             <Button onClick={this.onSubmit}>{strings.searchResults.updateSearch}</Button>
                        </Col>
                        <Col md={5} className={classes.improveSearch}>
                            <p>
                                <span>{strings.homePage.improveSearch + " "}</span>
                                <a href="/search">{strings.homePage.advancedSearch}</a>
                                <FaArrowCircleRight />
                            </p>
                        </Col>
                    </Row>
                </Container>
            </form>
        );
    };



    private onKeywordsChange = (e: any) => {
        this.setState({
            keywords: e.target.value,
        });
    };

    private onLocationChanged = (location: Location) => {
        this.setState({
            location,
        });
    };

    private onSubmit = (e: any) => {
        e.preventDefault();
        if (typeof window === 'undefined') {
            return;
        }
        const {
            location,
            keywords
        } = this.state;
        let model: SearchModel = {  
            keywords: keywords, 
            filters: {},
        };

        if (location.locationName) {
            if(!location.lat && !location.lng){
                getFirstSugestion()
                .then((location: Object) => {
                    model = { ...model, ...location };
                    console.log("HERE",model);
                    const searchUrl = withPrefix('/search-results/');
                    console.log(searchUrl);
                    navigate(`${searchUrl}?${serializeToQuery(model)}`);
                })
                .catch((err)=>{
                    console.log("HERE_ERR", err);
                })
            }
            else {
                model = { ...model, ...location};
                const searchUrl = withPrefix('/search-results/');
                navigate(`${searchUrl}?${serializeToQuery(model)}`);
            }
        } else {
            const searchUrl = withPrefix('/search-results/');
            navigate(`${searchUrl}?${serializeToQuery(model)}`);
        }
    };
}

export default withStyles(styles)(withStrings(UpdateSearch));
