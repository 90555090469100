import React from "react";
import { SearchModel } from "src/model/searchModel";
import { parseFromQuery, serializeToQuery } from "src/services/querySerializer";
import { WithStyles, withStyles } from "@material-ui/styles";
import { withStrings, WithStrings } from "components/locale";
import { styles } from "./container.styles";
import { Container, Col, Row } from "reactstrap";
import { navigate } from "@reach/router";
import SearchPanel from "components/home/search/search";
import VideoPlayer from "components/video-player/video-player";
import classNames from "classnames";

interface Props extends WithStyles<typeof styles>, WithStrings {
  location: Location;
}
interface State {
  searchModel: SearchModel;
}

class HomePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchModel: parseFromQuery(location.search),
    };
  }

  render = () => {
    const { classes, format, strings } = this.props;
    const { searchModel } = this.state;
    return (
      <div className={classes.homepageContainer}>
        <SearchPanel
          searchModel={searchModel}
          onSearchModelUpdated={this.onSearchModelUpdated}
        />
        <Container>
          <p className={classNames([classes.title, classes.intro])}>
            {strings.homePage.welcomeText.paragraph_1}
          </p>
          <p className={classes.intro}>
            {strings.homePage.welcomeText.paragraph_2}
          </p>
          <h2 className={classes.title}>
            {strings.homePage.whatIsCTSection.title}
          </h2>
          <p>{strings.homePage.whatIsCTSection.text}</p>
          <h2>{strings.homePage.whatIsCOVIDSection.title}</h2>
          <p>{strings.homePage.whatIsCOVIDSection.paragraph_1}</p>
          <p>{format(strings.homePage.whatIsCOVIDSection.paragraph_2)}</p>
          <h2>{strings.homePage.learnMoreSection.graphTitle}</h2>
          <h4>{strings.homePage.learnMoreSection.graphSubtitle}</h4>
          <p>{strings.homePage.learnMoreSection.graphText}</p>
          <img
            className="desktop-img"
            src="/images/graph.png"
            alt="Cases range graph"
          />
          <img
            className="mobile-img"
            src="/images/graph-mobile.png"
            alt="Cases range graph"
          />
          <Row className={classes.casesSection}>
            <Col xs={6} className={classes.alignCenter}>
              <h3>{strings.homePage.learnMoreSection.mildCases}</h3>
              <div className={classes.symptomsContainer + " mild"}>
                {strings.homePage.learnMoreSection.cases.mild.map(
                  (el, index) => (
                    <div key={index}>
                      <p>{el}</p>
                    </div>
                  )
                )}
              </div>
            </Col>
            <Col xs={6} className={classes.alignCenter}>
              <h3>{strings.homePage.learnMoreSection.severeCases}</h3>
              <div className={classes.symptomsContainer + " severe"}>
                {strings.homePage.learnMoreSection.cases.severe.map(
                  (el, index) => (
                    <div key={index}>
                      <p>{el}</p>
                    </div>
                  )
                )}
              </div>
            </Col>
          </Row>
          <p className={classes.source}>
            {format(strings.homePage.learnMoreSection.source)}
          </p>
          <Row className={classes.videoSection}>
            <Col
              className={"playerYouTube"}
              xs={{ size: 12, order: 1 }}
              md={{ size: 8, order: 0 }}
            >
              <VideoPlayer
                code={strings.homePage.videoSection.videoCode}
                width="100%"
                height="100%"
                title={strings.homePage.videoSection.title}
              />
            </Col>
            <Col xs={{ size: 12, order: 0 }} md={{ size: 4, order: 1 }}>
              <h3>{strings.homePage.videoSection.title}</h3>
              <p>{strings.homePage.videoSection.text}</p>
              <p className={classes.source}>
                {format(strings.homePage.videoSection.source)}
              </p>
            </Col>
            <Col xs={{ size: 12, order: 2 }} className={classes.infoLinks}>
              <a
                href="https://www.who.int/"
                target="_blank"
                rel="noopener noreferrer"
              >
                World Health Organization
              </a>
              <a
                href="https://www.nih.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                National Institute of Health
              </a>
              <a
                href="https://clinicaltrials.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ClinicalTrials.gov
              </a>
              <a
                href="https://www.coronavirus.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Coronavirus.gov
              </a>
            </Col>
          </Row>
        </Container>
        <div className={classes.banner}>
          <img src="/images/banner-img.png" alt="banner-image" />
          <Container>
            <Row>
              <Col md={7}>
                <p className={"title"}>
                  {strings.homePage.bannerSection.title}
                </p>
                <a className={classes.resourceLink} href="/faqs">
                  {strings.homePage.bannerSection.resource}
                </a>
                <p>{strings.homePage.bannerSection.text}</p>
                <p>
                  {strings.homePage.bannerSection.provider}
                  <a
                    className="logo"
                    href="https://www.ciscrp.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/images/ciscrp-logo.png" alt="ciscrp-logo"></img>
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  };

  componentDidMount(): void {
    // TODO: this is workaround. This should be saved in global state
    const query = "?" + serializeToQuery(this.state.searchModel);
    window["trialFinderSearchQuery"] = query;
  }

  onSearchModelUpdated = (searchModel: SearchModel) => {
    this.setState({ searchModel: { ...searchModel } });
    // TODO: this is workaround. This should be saved in global state
    const query = "?" + serializeToQuery(searchModel);
    window["trialFinderSearchQuery"] = query;
    navigate(query, {
      replace: true,
    });
  };
}

export default withStyles(styles)(withStrings(HomePage));
