import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    searchForm: {
        height: '550px',
        background: theme.color.background,
        color: theme.color.text,
        backgroundPosition: 'center, right bottom',
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'flex-end',
        borderBottom: '8px solid white',
        '& h1': {
            color: 'white',
            margin: '0 0 64px 0',    
            fontWeight: 300,
            whiteSpace: 'nowrap',
            fontSize: '50px',
            lineHeight: '64px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '44px',
                lineHeight: '48px',
                whiteSpace: 'pre-wrap',
                margin: '0 0 32px 0',
            },
            [theme.breakpoints.down('xs')]: {
                margin: '0 0 50px 0',
            },
        },
        '& label': {
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: 'bold',
        },
        '& input': {
            borderColor: theme.color.inputBorder,
            padding: '8px 16px',
            color: theme.color.text,
            backgroundColor: 'rgba(255,255,255, 0.2)',
            borderRadius: '4px',
            lineHeight: '24px',
            fontSize: '16px',
            height: '38px !important',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('md')]: {
                marginBottom: '30px',
            },
        },
        '& input::placeholder': {
            color: 'white !important',
            fontSize: '14px',
        },
        '& :-ms-input-placeholder': {
            color: 'white !important',
            fontSize: '14px',
        },
        '& input::-ms-clear': {
            display: 'none',
        },
        '& button:hover': {
            backgroundColor: '#00ACD9',
            color: 'rgba(255,255,255)',
        },
        '& button': {
            borderColor: theme.color.inputBorder,
            backgroundColor: 'white',
            textTransform: 'uppercase',
            color: '#00ACD9',
            fontSize: '16px',
            fontWeight: 600,
            letterSpacing: 0,
            padding: '8px 16px',
            lineHeight: '20px',
            marginTop: '30px',
            [theme.breakpoints.down('md')]: {
                minWidth: '110px',
                fontSize: '14px',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                marginTop: '15px',
                width: '100%',
            },
        },
    },
    locationInput: {},
    improveSearch: {
        marginTop: '120px',
        '& p': {
            width: 'fit-content',
            backgroundColor: 'white',
            borderRadius: '6px 6px 0px 0px',
            padding: '10px 20px',
            margin: '0 !important',
            whiteSpace: 'nowrap',
        },
        '& span':{
            color: '#4D4D4D',
        },
        '& a': {
            fontWeight: 'bold',
            color: '#00ACD9',
            textDecoration: 'underline',
        },
        '& svg': {
            marginLeft: '4px',
            marginBottom: '4px',
            color: '#00ACD9',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px',
            '& p': {
                whiteSpace: 'pre-line',
             },
            '& span': {
                display: 'none'
            }
        },
    }
});
