import * as React from 'react';
import Layout from 'components/layout/layout';
import Container from 'components/home/container/container';

export default ({ location }: { location: Location }) => {
    return (
        <Layout location={location} title={'Welcome to Coronavirus Clinical Trials'} description={'This website is provided as a free global resource to both researchers and the general public on COVID-19 clinical trials. Check this website regularly for new COVID-19 trials, as this information is automatically updated from ClinicalTrials.gov.'} tenantCss={'resultsCssUrl'}>
            <Container location={location} />
        </Layout>
    );
};
