import * as React from "react";

interface Props {
  title: string;
  code: string;
  width: string;
  height: string;
}

class YoutubeVideoPlayer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render = () => {
    const { title, code, width, height } = this.props;
    return (
      <iframe
        title={title}
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${code}?autoplay=0&rel=0`}
        frameBorder="0"
        allow="accelerometer;autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  };
}

export default YoutubeVideoPlayer;
